import Rollbar from "rollbar";

const rollbar = new Rollbar({
  accessToken: process.env.REACT_APP_ROLLBAR_CLIENT_ACCESS_TOKEN,
  environment: process.env.REACT_APP_ROLLBAR_ENV || process.env.NODE_ENV,
  captureUncaught: true,
  captureUnhandledRejections: true,
});

export default rollbar;
