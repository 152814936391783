import React, { createContext, useEffect, useState } from "react";

const LayoutContext = createContext({
  fullscreen: false,
  setFullscreen: () => {},
});

export const LayoutProvider = ({ children }) => {
  const [fullscreen, setFullscreen] = useState(false);

  const [value, setValue] = useState({
    fullscreen,
    setFullscreen,
  });

  useEffect(() => {
    setValue((value) => ({ ...value, fullscreen }));
  }, [fullscreen]);

  return (
    <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
  );
};

export default LayoutContext;
