import React, { Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Loader from "react-loader";

import "./rollbar";

import { AuthProvider } from "./context/AuthContext";
import { LayoutProvider } from "./context/LayoutContext";
import Notifications from "./components/Notifications";

const AdminApp = React.lazy(() =>
  import(
    /* webpackChunkName: "AdminApp" */
    "./AdminApp"
  )
);
const App = React.lazy(() =>
  import(
    /* webpackChunkName: "App" */
    "./App"
  )
);

const Main = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Notifications />
        <Suspense fallback={<Loader />}>
          <Switch>
            <Route path="/admin" component={AdminApp} />
            <LayoutProvider>
              <Route component={App} />
            </LayoutProvider>
          </Switch>
        </Suspense>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Main;
