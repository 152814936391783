import React, { createContext, useEffect, useState } from "react";

import api from "../api";

const AuthContext = createContext({
  user: {},
  signup: () => {},
  login: () => {},
  logout: () => {},
  update: () => {},
  changePassword: () => {},
  verify: () => {},
  recover: () => {},
  reset: () => {},
  oauth: () => {},
});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState();

  useEffect(() => {
    fetchUser(); // should we first do a check to see if we have a session cookie?
  }, []);

  const fetchUser = () => {
    return api
      .get("/user")
      .then((response) => {
        const { user } = response.data;
        if (user) {
          setUser(user);
        } else {
          setUser(null);
        }
      })
      .catch(() => {
        setUser(null);
      });
  };

  const reload = () => {
    fetchUser();
  };

  const signup = (userData) => {
    return api
      .post("/auth/signup", userData, { withCredentials: true })
      .then((response) => {
        fetchUser();

        const { user } = response.data;
        return user;
      });
  };

  const login = (userData) => {
    return api
      .post("/auth/login", userData, { withCredentials: true })
      .then((response) => {
        fetchUser();

        const { user } = response.data;
        return user;
      });
  };

  const logout = () => {
    return api.get("/auth/logout", { withCredentials: true }).then(() => {
      setUser(null);
    });
  };

  const update = (userData) => {
    return api.patch("/user", userData).then(fetchUser);
  };

  const changePassword = (old_password, password) => {
    return api.patch("/user/password", { old_password, password });
  };

  const verify = (email, token) => {
    return api.post("/auth/verify", { email, token }).then(fetchUser);
  };

  const setup = (email, token, password) => {
    return api.post("/auth/setup", { email, token, password }).then(fetchUser);
  };

  const recover = (email) => {
    return api.post("/auth/recover", { email });
  };

  const reset = (email, token, password) => {
    return api.post("/auth/reset", { email, token, password }).then(fetchUser);
  };

  const oauth = (provider, { accessToken }) => {
    return api
      .post(
        `/auth/${provider}`,
        { access_token: accessToken },
        { withCredentials: true }
      )
      .then((response) => {
        fetchUser();

        const { user } = response.data;
        return user;
      });
  };

  const [value, setValue] = useState({
    user,
    reload,
    signup,
    login,
    logout,
    update,
    changePassword,
    verify,
    setup,
    recover,
    reset,
    oauth,
  });

  useEffect(() => {
    setValue((value) => ({ ...value, user }));
  }, [user]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContext;
