import React from "react";

import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";

const Notifications = () => {
  return <NotificationContainer />;
};

export default Notifications;
